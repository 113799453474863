<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini" v-model="searchParams">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            clearable
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchParams.depart_id"
            filterable
            clearable
            placeholder="所属机关"
          >
            <el-option
              v-for="item in departData"
              :key="item.depart_id"
              :value="item.depart_id"
              :label="item.depart_name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="searchParams.date"
            type="date"
            format="yyyy-MM-dd"
            default-value
            value-format="yyyy-MM-dd"
            placeholder="日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import { getHistoryPrice, ExportHistoryPrice } from '@/api/stat';
import { getDepartDetail } from '@/api/gas_info_mgr';

import mixins from '@/mixins';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    var _date = new Date();
    _date = new Date(_date.getTime() - 24 * 60 * 60 * 1000);
    var _y = _date.getFullYear();
    var _m = _date.getMonth() + 1;
    var _d = _date.getDate();

    var date = _y + '-' + _m + '-' + _d;
    return {
      tableLoading: false,
      searchParams: {
        date: date,
        depart_id: null
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          align: 'center',
          fixed: true,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          align: 'left',
          fixed: true,
          minWidth: 210
        },
        {
          title: '所属辖区',
          key: 'depart_name'
        },
        {
          title: '92#汽油',
          align: 'center',
          key: '92'
        },
        {
          title: '95#汽油',
          align: 'center',
          key: '95'
        },
        {
          title: '98#汽油',
          align: 'center',
          key: '98'
        },
        {
          title: '0#柴油',
          align: 'center',
          key: '0'
        },
        {
          title: '-10#柴油',
          align: 'center',
          key: '-10'
        },
        {
          title: '日期',
          align: 'center',
          key: 'time'
        }
      ],
      data: [],
      // date: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      departData: []
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    // if (this.$route.query.status) this.searchParams.status = '0';
    this.pathMatch = this.$router.history.current.matched;

    this.getList();

    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.tableLoading = true;
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      getHistoryPrice(params).then(res => {
        // console.log(res);
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        }
        this.tableLoading = false;
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };
      ExportHistoryPrice(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    getList() {
      getDepartDetail({ type: 1 }).then(res => {
        let { code, data } = res;
        if (data && code === 0) {
          let redata = data.filter(item => {
            return item;
          });
          this.departData = redata;
        }
      });
    },
    getTime(e) {
      if (e) {
        this.searchParams.begin = e[0];
        this.searchParams.end = e[1];
      } else {
        this.searchParams.begin = '';
        this.searchParams.end = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
